import React from "react";
import {
  MDBFooter,
  MDBContainer,
} from "mdb-react-ui-kit";

import { BsTwitter, BsDiscord } from "react-icons/bs";
const Footer = () => {
  return (
    <MDBFooter
      className=" text-center text-white"
      style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
    >
      <MDBContainer className="p-4 pb-0">
        <h1
          style={{
            color: "#fff",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {" "}
          JOIN THE COMMUNITY
        </h1>
        <section className="mb-4">

          <a className="btn btn-outline-light m-3" href="https://twitter.com/ForLootAndGlory" role="button">
            <BsTwitter />
          </a>
          <a className="btn btn-outline-light m-3" href="https://discord.gg/rVMfQnERnW" role="button">
            <BsDiscord />
          </a>
        </section>
      </MDBContainer>

      <div
        className="text-center p-3"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}
      >
        © {new Date().getFullYear()} Copyright:
        <a className="text-white" href="https://mdbootstrap.com/">
          MDBootstrap.com
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
