import React from "react";
import mHeading from "../images/mHeading.png";
import Zoom from "react-reveal/Zoom";


const HeadSection = () => {
  return (
    <div>
      {" "}
      <div id='home' className="section1">
        <div className="lastSpliterSec1 "> </div>
        <div style={{ top: "0", left: "0", right: "0" }}>
          {/*<video className="videoTag" loop="true" autoplay="autoplay" muted>
            <source src={gloryBgVideo} type="video/mp4" />
          </video>*/}
        </div>
        <div className="sections">
          <div className="firstLayer ">
              <div className="mHead">
                <img src={mHeading} className="w-100 p-3" alt="..." />
              </div>
            <Zoom top>
              <div className="text-center  pt-5" style={{ marginTop: "10%" }}>
                <h4>
                  <strong style={{ fontSize: "20px", color: "white" }}>
                    <span style={{ fontFamily: "Lato" }}>
                      As a true pirate, join us to Drink, Sail, Fight and F*#$ !
                      Decentralized, a lot of burn and 100% on-chain, powered by Skale Network!
                    </span>
                  </strong>
                </h4>
              </div>
            </Zoom>
            <Zoom top>
              <div className="text-center mt-5">
                <a href="https://testnet.forlootandglory.io/" className="eBtn">
                  ENTER GAME TESTNET
                </a>
              </div>
            </Zoom>
          </div>
        </div>

        <div className="sections">

          <div className="text-center mt-3 sec2Con">
            <div
              className="mt-1"
              style={{ display: "flex", justifyContent: "center" }}
            >

              <div className="sec2ParaContainer" style={{ width: "60%" }}>
                <Zoom left>
                  <p>
                    <em className="sec2Para">
                      Our pirate-themed game is a RPG / strategy game. You will be able to hunt for coins,
                      level-up your characters, craft gears and many other things described below. The layer used
                      is <strong>Skale</strong> and all transactions gas fees are <strong>Free</strong>.
                      The first chapter is <strong>PVE</strong>. <br /> <br /> It will first be deployed
                      on <strong>testnet</strong> to allow people to actually play the game before investing any cent as we believe it is
                      how it should always be instead of selling something without delivering the game first.<br /><br /> During this
                      phase, you will be rewarded with the free-to-mint (with conditions) NFT called the <strong>FLAG PIRATE NFT</strong>
                      . With it, you’ll be able to start your pirate’s journey.{" "}
                    </em>
                    <em className="sec2Para">
                      <br />
                      <br />Join our Discord, log in to our Dapps and claim your role
                      based on the games you are active in, this will provide
                      access to <strong>events</strong> and many{" "}
                      <strong>rewards</strong>.
                    </em>
                  </p>
                </Zoom>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeadSection;
