import React, { } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "./styles.css";
// Import slider image
import Slide1 from "../images/slide/female30.jpg";
import Slide2 from "../images/slide/samp1.jpg";
import Slide3 from "../images/slide/samp10.jpg";
import Slide4 from "../images/slide/samp2.jpg";
import Slide5 from "../images/slide/samp24.jpg";
import Slide6 from "../images/slide/samp28.jpg";
import Slide7 from "../images/slide/samp29.jpg";
import Slide8 from "../images/slide/samp3.jpg";
import Slide9 from "../images/slide/samp31.jpg";
import Slide10 from "../images/slide/samp32.jpg";
import Slide11 from "../images/slide/samp33.jpg";
import Slide12 from "../images/slide/samp37.jpg";
import Slide13 from "../images/slide/samp4.jpg";
import Slide14 from "../images/slide/samp44.jpg";
import Slide15 from "../images/slide/samp49.jpg";

// import required modules
import { Pagination, Autoplay } from "swiper";
import "./Roadmap.scss";

const Slider = () => {
  return (
    <div id="slider">
      <br />
      <br />
        <h1
          style={{
            color: "#fff",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {" "}
          FLAG Pirate NFT
        </h1>
        <h1 style={{
          color: "#fff",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          fontSize: '1.5rem',
        }}> <i>(free mint)</i></h1>
      <br />
          <Swiper
            effect={"coverflow"}
            spaceBetween={30}
            grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Pagination]}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            pagination={true}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={Slide15} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <br />
            <SwiperSlide>
              <img src={Slide2} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <br />
            <SwiperSlide>
              <img src={Slide14} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <br />
            <SwiperSlide>
              <img src={Slide4} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide12} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide6} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide10} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide8} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide9} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide7} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide11} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide5} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide13} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide3} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={Slide1} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
          </Swiper>
    </div>
  );
};

export default Slider;
