import React from "react";
import Skale from "../images/skale.png";

const Partner = () => {
  return (
    <div>
      {" "}
      <div className="section5 mt-5">
        <div className="row Con10sec4 pb-5">
          <h2 className="text-white text-center mt-5 pt-5 joinCom">
            Powered by
          </h2>
          <div className="col-md-12">
            <div className="fContainer">
               <img src={Skale} alt="..." className="F2Log" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Partner;
