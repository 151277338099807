import React from "react";

import {
  Nave,
  Slider,
  HeadSection,
  LitePaper,
  Card,
  Partner,
  Footer
} from "../src/Components/index.jsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "swiper/css";
function App() {
  return (
    <div>
      {" "}
      <div className="bTag">
        <div className="  mainContent">
          <div className="pageCon">
            <Nave />
            <HeadSection />
            <Partner />
            <Slider />
            <LitePaper />
            {/* <Roadmap /> */}
            {/*<Vision />*/}
            <Card />
            {/* <ComparSlider />*/}
            {/* <FAQ /> */}
            {/* <TDSldier /> */}
            <Footer />
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
