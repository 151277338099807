import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import logo from "../images/fort-logo.png";

import Spin from "react-reveal/Spin";
const Nave = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      variant="dark"
      position="fixed"
      style={{ backgroundColor: "#000000 ", borderRadius: "59px 59px 0px 0px" }}
    >
      <Container>
        <Navbar.Brand href="/">
          <Spin>
            <img src={logo} alt="logo" style={{ height: "50px" }} />
          </Spin>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link href="https://testnet.forlootandglory.io/" target="_blank"> Game Testnet</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Nave;
