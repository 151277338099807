import React, { } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import card1 from "../images/game_character.png";
import card2 from "../images/game_ship.png";
import card3 from "../images/game_forge.png";
import card4 from "../images/game_tavern.png";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import Bounce from "react-reveal/Bounce";
import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination, Autoplay } from "swiper";
import "./Roadmap.scss";

const TDSldier = () => {
  return (
    <div id="slider">
      <br />
      <br />
      {/* <Bounce top>
        <h1
          style={{
            color: "#fff",
            borderRadius: "20px",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          {" "}
         NFT Worlds Pirate Metaverse
        </h1>
      </Bounce> */}

      <br />

      <Bounce>
        <Bounce right>
          <Swiper
            effect={"coverflow"}
            grabCursor={true}
            centeredSlides={true}
            slidesPerView={"auto"}
            coverflowEffect={{
              rotate: 50,
              stretch: 0,
              depth: 100,
              modifier: 1,
              slideShadows: true,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            pagination={true}
            modules={[EffectCoverflow, Autoplay, Pagination]}
            className="mySwiper"
          >
            <SwiperSlide>
              <img src={card1} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={card2} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={card3} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={card4} style={{ height: "100%" }} alt="Pirate nft" />
            </SwiperSlide>
          </Swiper>
        </Bounce>
      </Bounce>
    </div>
  );
};

export default TDSldier;
