import React from "react";
// import sec4Heading from "../images/sec4Heading.png";
import CardCom from "./CardCom";
import Bounce from "react-reveal/Bounce";
import RubberBand from 'react-reveal/RubberBand';
// import Economy from '../images/Economy.png';
const Card = () => {
  return (
    <div id='tok'>
      <div className="section2">
        <div className="sectionsSimple">
        <RubberBand>
          {/*<h1
            style={{
              color: "#fff",
              borderRadius: "20px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            className=" w-100  text-white joinCom"
          >
            {" "}
            TOKENOMIC
          </h1>*/}
          </RubberBand>
          {/* <div className=" text-center mt-5 pt-5">
            <img src={Economy} alt="..." className="w-75 my-5" />
          </div>
          <div className="text-center my-5">
            <img src={sec4Heading} alt="..." className="sec4HeadLogo" />
          </div> */}
          <div className="row m-0 Con10">
            <div className="col-md-10">
              <div className="row m-0 px-3">
                <Bounce left>
                  <div
                    className="col-md-4"
                    style={{
                      fontFamily: "Arial",
                      fontWeight: "normal",
                      fontSize: "small",
                    }}
                  >
                    <CardCom
                      title={"DECENTRALIZATION"}
                      p1={
                        "Players can enjoy an autonomous gaming experience where community interaction and strategic gameplay lead to potential earnings."
                      }
                      p2={
                        "Having everything on-chain, with the front-end (website) mirrored on Arweave, ensuring the game remains playable regardless of circumstances. ENS domain. We consider the project to be 100% decentralized once keys (smart contracts) are thrown away."
                      }
                    />
                  </div>
                </Bounce>
                <Bounce bottom>
                  <div className="col-md-4">
                    <CardCom
                      title={"ECONOMIC"}
                      p1={
                        "Through a carefully designed economic model, the game ensures long-term engagement and viability, benefiting both new and existing players."
                      }
                      p2={
                        "Implementing a halving mechanism for our sole in-game token ($Barrels), similar to Bitcoin's, which will be essential for gameplay and most actions.  Plus, this $barrels will be NFTs and not ERC20 tokens. Additionally, those aiming for the lucrative legendary level must be willing to take the risk of burning characters and other assets."
                      }
                    />
                  </div>
                </Bounce>

                <Bounce right>
                  <div className="col-md-4">
                    <CardCom
                      title={"GAMEPLAY"}
                      p1={
                        "Introduces true competitive elements to enhance player interaction and engagement."
                      }
                      p2={
                        "Reserving substantial rewards, the treasure chest (end-game), for dedicated players. Others can also profit with their assets by selling them, spending some time and fees in-game."
                      }
                    />
                  </div>
                </Bounce>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
