import React from "react";

const LitePaper = () => {
  return (
    <div className="container">
      <br />
      <br />
      <h1
        style={{
          color: "#fff",
          borderRadius: "20px",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
        }}
      >
        {" "}
        <div className="sections">
          <div className="text-center mt-3 sec2Con">
            <a href="https://docs.forlootandglory.io/flag-pirate-game/introduction" className="eBtn slTextBtn" target="_blank" rel="noreferrer" >
              DOCS
            </a>
          </div>
        </div>
      </h1>
      <br />
    </div>
  );
};

export default LitePaper;
