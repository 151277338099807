import React from 'react'
import cardCross from '../images/cardCross.png';

function CardCom({title,p1,p2}) {
  return (
    <div className='cardContainer'>
        <div className='startCard w-100'>
            <img src={cardCross} alt="Pirate nft"/>
            <h4 className='cardH text-white my-3'><span>{title}</span></h4>
            <p><span className='cardP' style={{color:"#fff"}} >{p1}</span></p>
            <p><span className='cardP' style={{color:"#fff"}} >{p2}</span></p>
        </div>
    </div>
  )
}

export default CardCom